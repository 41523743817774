import React from 'react';
import { AuthProvider } from "./features/auth/AuthProvider";
import AppRoutes from "./AppRoutes";
import './App.scss';

export default function App() {
  return (
    <AuthProvider>
        <AppRoutes />
    </AuthProvider>
  );
}
