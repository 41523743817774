import {useCallback, useMemo, useState} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {Table, TableProps, Button, Space, Result, Flex, Breadcrumb, Upload, Popconfirm, Tooltip} from 'antd';
import { DeleteOutlined, DownloadOutlined, ArrowLeftOutlined, UploadOutlined, ExportOutlined } from '@ant-design/icons';
import { formatBytes, formatDate } from "../../../lib/utils";
import DocumentIcon from '../DocumentIcon';
import './styles.css'
import {useDeleteDocumentMutation, useGetFolderInfoQuery, useAddDocumentMutation} from "../documentsApi";
import { useGetAuthProfileQuery } from '../../auth/authApi';

interface DataType {
  id: number;
  name: string;
  size: number;
  createdAt: string;
}

export default function DocumentsScreen() {
  const navigate = useNavigate();
  const { belongsTo, folderId }: any = useParams();
  const [isUploadingDocument, setIsUploadingDocument] = useState(false);
  const [nowDeletingIds, setNowDeletingIds] = useState<number[]>([]);

  const { data: folderInfo, isLoading: isLoadingFolderInfo, isFetching, isSuccess: isSuccessFolderInfo, isError: isErrorFolderInfo  } = useGetFolderInfoQuery({belongsTo, folderId})
  const [ deleteDocument, {} ] = useDeleteDocumentMutation();
  const [ addDocument, {} ] = useAddDocumentMutation();

  const { data: authProfile } = useGetAuthProfileQuery(undefined);
  const isNotMember = authProfile.role !== 'Member';

  const columns: TableProps<DataType>['columns'] = useMemo(() => {
    return [
      {
        title: 'Անվանում',
        dataIndex: 'name',
        key: 'name',
        render: (name: string, record: any) => <div className='document-name-container'>
          <DocumentIcon fileName={name} />{name}
        </div>,
      },
      {
        title: 'Ֆայլի չափս',
        dataIndex: 'size',
        key: 'size',
        render: (text: string) => formatBytes(text),
      },
      {
        title: 'Ստեղծման ամսաթիվ',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (date: string) => <Tooltip title={formatDate(date)}>{formatDate(date, true)}</Tooltip>
      },
      {
        title: 'Գործողություններ',
        dataIndex: 'action',
        align: 'right',
        key: 'action',
        render: (_, record: any) => <Space>
          <Button
              icon={<DownloadOutlined style={{fontSize: 22}} />}
              href={`${process.env.REACT_APP_API_BASE_URL}/documents/file/${record.id}`}
              target='_blank'
              type='primary'
              ghost
              disabled={nowDeletingIds.includes(record.id)}
            >
              Ներբեռնել
          </Button>
          {
            isNotMember && 
            <Popconfirm
              title='Վստահ ե՞ք, որ ցանկանում եք հեռացնել այս ֆայլը'
              // description='ayn kkorchi anveradardz'
              onConfirm={() => { handleDeleteDocument(record.id) }}
              okText="Այո"
              okButtonProps={{ danger: true }}
              cancelText="Ոչ"
            >
              <Button icon={<DeleteOutlined style={{fontSize: 22}} />} type='primary' danger ghost loading={nowDeletingIds.includes(record.id)} />
            </Popconfirm>
          }
        </Space>,
      },
    ]
  }, [nowDeletingIds, isNotMember]);

  const handleDeleteDocument = useCallback(async (documentId: number) => {
    setNowDeletingIds(ids => ( [...ids, documentId] ));
    await deleteDocument(documentId);
    setNowDeletingIds(nowDeletingIds.filter(id => id !== documentId));
  }, []);

  const handleAddFileChange = useCallback(async ({ file }: any) => {
    if (file.status === 'uploading' && !isUploadingDocument) {
      setIsUploadingDocument(true);
    } else {
      if (file.status === 'done' && file.response) {
        const uploadedFileData = file.response;
        await addDocument({belongsTo, folderId, file: uploadedFileData});
        // await fetchFolderInfo(belongsTo, folderId, false);
        // await fetchFoldersStructure();
      } else {
        alert('Error');
      }
      setIsUploadingDocument(false);
    }
  }, [])

  if (isErrorFolderInfo) {
    return (
      <Result
        status="500"
        title="500"
        subTitle="Sorry, something went wrong."
        extra={<Button type="primary">Back Home</Button>}
      />
    )
  }

  return (
    <>
      <Flex flex={1} justify='space-between' align='center' style={{padding: '16px 0'}}>
        <Space>
          <Button icon={<ArrowLeftOutlined style={{fontSize: 28}} />} type='text' onClick={() => navigate(-1)} />
          <Breadcrumb
            separator='>'
            items={isSuccessFolderInfo ? folderInfo.parents.map((parent: string) => ({ title: parent })) : []}
          />
        </Space>
        {
          isNotMember &&
          <Upload
            action={`${process.env.REACT_APP_API_BASE_URL}/file`}
            withCredentials
            showUploadList={false}
            onChange={handleAddFileChange}
            disabled={isUploadingDocument}
          >
            <Button
              type='primary'
              loading={isUploadingDocument}
              icon={<UploadOutlined style={{fontSize: 20}} />}
            >
              Ավելացնել ֆայլ
            </Button>
          </Upload>
        }
      </Flex>
      <Table
        columns={columns}
        sticky
        dataSource={folderInfo?.documents}
        rowKey={(record) => `${record.id}-${record.name}`}
        pagination={false}
        loading={isFetching}
        locale={{...(isLoadingFolderInfo && {emptyText: 'Տվյալները բեռնվում են'})}}
      />
    </>
  )
}