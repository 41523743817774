import React, {useEffect, useMemo, useState, useCallback} from "react";
import dayjs from 'dayjs';
import {Button, Col, Flex, Form, Input, Radio, Row, Select, Collapse, DatePicker, InputNumber, Space, Tag, Badge, Spin} from "antd";
import { SearchOutlined, FilterOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';
import useMembersFilters from "../useMembersFilters";
import './styles.css';
import {useGetAvailableSexesQuery} from "../../sex/sexApi";
import {useGetAvailableRegionsQuery, useGetAvailableTerritorialCentersQuery} from "../../region/regionApi";
import {useGetAvailablePositionsInThePartyQuery} from "../../positionsInTheParty/positionsInThePartyApi";
import {useGetMembersCountQuery} from "../membersApi";
import { useGetAvailableMemberStatusQuery } from '../../memberStatus/memberStatusApi';

export const AdvancedSearchForm = ({allMembersCount, headerRightElements} : {allMembersCount: any, headerRightElements: any}) => {
  const [form] = Form.useForm();
  const [expand, setExpand] = useState(false);

  const {filters, setFilters, setPage, AVAILABLE_FILTERS_FIELDS, removeFilters}: any = useMembersFilters();

  const formInitialValues = useMemo(() => {
    const initialValues = {...filters};
    if ('startDateOfApplicationCompletion' in initialValues)
      initialValues.startDateOfApplicationCompletion = dayjs(initialValues.startDateOfApplicationCompletion, 'YYYY-MM-DD');
    if ('endDateOfApplicationCompletion' in initialValues)
      initialValues.endDateOfApplicationCompletion = dayjs(initialValues.endDateOfApplicationCompletion, 'YYYY-MM-DD');
    if ('startDateOfBecomingPartyMember' in initialValues)
      initialValues.startDateOfBecomingPartyMember = dayjs(initialValues.startDateOfBecomingPartyMember, 'YYYY-MM-DD');
    if ('endDateOfBecomingPartyMember' in initialValues)
      initialValues.endDateOfBecomingPartyMember = dayjs(initialValues.endDateOfBecomingPartyMember, 'YYYY-MM-DD');

    return initialValues;
  }, [JSON.stringify(filters)])

  const selectedExtendedFiltersCount = useMemo(() => {
    const extendedFilters = Object.keys(filters).filter((filter) => AVAILABLE_FILTERS_FIELDS[filter].extended);
    return extendedFilters.length;
  }, [JSON.stringify(filters)])

  const formSubmit = (data: any) => {
    if ('startDateOfApplicationCompletion' in data && data.startDateOfApplicationCompletion)
      data.startDateOfApplicationCompletion = data.startDateOfApplicationCompletion.format('YYYY-MM-DD');
    if ('endDateOfApplicationCompletion' in data && data.endDateOfApplicationCompletion)
      data.endDateOfApplicationCompletion = data.endDateOfApplicationCompletion.format('YYYY-MM-DD');
    if ('startDateOfBecomingPartyMember' in data && data.startDateOfBecomingPartyMember)
      data.startDateOfBecomingPartyMember = data.startDateOfBecomingPartyMember.format('YYYY-MM-DD');
    if ('endDateOfBecomingPartyMember' in data && data.endDateOfBecomingPartyMember)
      data.endDateOfBecomingPartyMember = data.endDateOfBecomingPartyMember.format('YYYY-MM-DD');

    setPage(1);
    setFilters(data);
  }

  useEffect(() => {
    form.resetFields();
  }, [JSON.stringify(filters)]);

  const onClickRadioSex = useCallback((e: { target: { value: any; }; }) => {
    if (e.target.value === form.getFieldValue('sex')) {
      form.setFieldValue('sex', null);
    }
  }, []);

  const onClickRadioRemark = useCallback((e: { target: { value: any; }; }) => {
    if (e.target.value === form.getFieldValue('remark')) {
      form.setFieldValue('remark', null);
    }
  }, []);

  return (
    <Form
      form={form}
      name="advanced_search"
      layout="vertical"
      className="ccp-form-items"
      onFinish={formSubmit}
      initialValues={formInitialValues}
    >
      <Flex justify='space-between'>
        <Row gutter={18} style={{ flex: 1}}>
          <Col span={9}><Form.Item name='fullName'><Input prefix={<SearchOutlined />} placeholder='Ազգանուն Անուն' allowClear /></Form.Item></Col>
          <Col span={4}><Form.Item name='phoneNumber'><Input prefix={<SearchOutlined />} placeholder='Հեռախոս' allowClear /></Form.Item></Col>
          {/*<Col><Form.Item name='emailAddress'><Input prefix={<SearchOutlined />} placeholder='Էլ․ հասցե' allowClear /></Form.Item></Col>*/}
          <Col>
            <Space size={18}>
              <Button htmlType='submit' icon={<SearchOutlined />}>Որոնել</Button>
              <Badge
                count={selectedExtendedFiltersCount}>
                <Button icon={<FilterOutlined />} onClick={() => {setExpand(!expand)}}>Ֆիլտրեր</Button>
              </Badge>
            </Space>
          </Col>
        </Row>
        <div>{headerRightElements}</div>
      </Flex>

      <Collapse
        activeKey={[Number(expand)]}
        className='filters-panel-collapse'
        items={[
          {
            key: '1',
            label: null,
            children: <SearchFiltersExtendPanel allItemsCount={allMembersCount} onClickRadioSex={onClickRadioSex} onClickRadioRemark={onClickRadioRemark} />
          }
        ]}
      />

      {Object.keys(filters).length > 0 &&
        <Space wrap>
          { Object.keys(filters).map((filter) => {
            return (
              <Tag
                closable
                key={filter}
                onClose={() => {removeFilters([filter])}}
              >
                {AVAILABLE_FILTERS_FIELDS[filter].label}
              </Tag>
            )
          })}

          <Button size='small' type='text' onClick={() => {removeFilters(Object.keys(filters))}}>Չեղարկել</Button>
        </Space>
      }
    </Form>
  )
}


const SearchFiltersExtendPanel = ({ allItemsCount, onClickRadioSex, onClickRadioRemark }: {allItemsCount: any; onClickRadioSex: any, onClickRadioRemark: any}) => {
  const {data: availableSexes, isSuccess: isSuccessAvailableSexes} = useGetAvailableSexesQuery(undefined);
  const {data: availableRegions, isSuccess: isSuccessAvailableRegions} = useGetAvailableRegionsQuery(undefined);
  const {data: availableTerritorialCenters, isSuccess: isSuccessAvailableTerritorialCenters} = useGetAvailableTerritorialCentersQuery(undefined);
  const {data: availablePositionsInTheParty, isSuccess: isSuccessAvailablePositionsInTheParty} = useGetAvailablePositionsInThePartyQuery(undefined);
  const {data: availableMemberStatuses, isSuccess: isSuccessAvailableMemberStatuses} = useGetAvailableMemberStatusQuery(undefined);

  const { filters }: any = useMembersFilters();
  const { isFetching: isFetchingMembersCount, data: allMembersCount } = useGetMembersCountQuery(filters);

  return (
    <>
      <Row gutter={24} className='filters'>
        <Col span={10} style={{ paddingRight: '2rem' }}>

          <div className='filters-group-label'>Տեղանք</div>
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item label="մարզ" name="regions">
                {isSuccessAvailableRegions ?
                  <Select mode="multiple" allowClear placeholder='Ընտրել' fieldNames={{ label: 'name', value: 'id' }} options={availableRegions} optionFilterProp='name' />
                  : <LoadingOutlined />
                }
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="տարածքային կազմակերպություն" name="territorialCenters">
                {isSuccessAvailableTerritorialCenters ?
                  <Select mode="multiple" showSearch allowClear placeholder='Ընտրել' fieldNames={{ label: 'name', value: 'id' }} options={availableTerritorialCenters} optionFilterProp='name' />
                  : <LoadingOutlined />
                }
              </Form.Item>
            </Col>
          </Row>

          <div className='filters-group-label'>Ամսաթվեր</div>
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item label='դիմումի լրացման օր' name='startDateOfApplicationCompletion'>
                <DatePicker
                  format={{
                    format: 'DD/MM/YYYY',
                    type: 'mask'
                  }}
                  style={{width: '100%'}}
                  placeholder='Օր-Ամիս-Տարի'
                  disabledDate={(currentDate: any) => {
                    const now = new Date();
                    return currentDate > now;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='դիմումի լրացման օր' name='endDateOfApplicationCompletion'>
                <DatePicker
                  format={{
                    format: 'DD/MM/YYYY',
                    type: 'mask'
                  }}
                  style={{width: '100%'}}
                  placeholder='Օր-Ամիս-Տարի'
                  disabledDate={(currentDate: any) => {
                    const now = new Date();
                    return currentDate > now;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='կուսակցական դառնալու օր' name='startDateOfBecomingPartyMember'>
                <DatePicker
                  format={{
                    format: 'DD/MM/YYYY',
                    type: 'mask'
                  }}
                  style={{width: '100%'}}
                  placeholder='Օր-Ամիս-Տարի'
                  disabledDate={(currentDate: any) => {
                    const now = new Date();
                    return currentDate > now;
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label='կուսակցական դառնալու օր' name='endDateOfBecomingPartyMember'>
                <DatePicker
                  format={{
                    format: 'DD/MM/YYYY',
                    type: 'mask'
                  }}
                  style={{width: '100%'}}
                  placeholder='Օր-Ամիս-Տարի'
                  disabledDate={(currentDate: any) => {
                    const now = new Date();
                    return currentDate > now;
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

        </Col>
        <Col span={10} className='member-info-col' style={{ paddingLeft: '2rem' }}>

          <div className='filters-group-label'>Անձնական տվյալներ</div>
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item label='սեռ' name='sex' className='ccp-radio'>
                {isSuccessAvailableSexes ?
                  <Radio.Group>
                    {availableSexes.map((sex: any) => (<Radio value={sex.id} onClick={onClickRadioSex} key={sex.id}>{sex.name}</Radio>))}
                  </Radio.Group>
                  : <LoadingOutlined />
                }
              </Form.Item>
            </Col>
            <Col span={12}><Form.Item label='մասնագիտություն' name='profession'><Input  /></Form.Item></Col>
          </Row>

          <div className='filters-group-label'>Կուսակցություն</div>
          <Row gutter={14}>
            <Col span={12}>
              <Form.Item label="զբաղվածություն" name="employment">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="կուսակցական պաշտոն" name="positionInTheParty">
                { isSuccessAvailablePositionsInTheParty ?
                  <Select mode="multiple" allowClear placeholder='Ընտրել' fieldNames={{ label: 'name', value: 'id' }} options={availablePositionsInTheParty} />
                  : <LoadingOutlined />
                }
              </Form.Item>
            </Col>
            {/* <Col span={12}><Form.Item label='նկատողություններ' name='remark'><InputNumber min={0} style={{width: '100%'}} /></Form.Item></Col> */}
            <Col span={12}>
              <Form.Item label='նկատողություններ' name='remark' className='ccp-radio'>
                <Radio.Group>
                  <Radio value={'1'} onClick={onClickRadioRemark} key={'1'}>Այո</Radio>
                  <Radio value={'0'} onClick={onClickRadioRemark} key={'0'}>Ոչ</Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="կարգավիճակ" name="status">
                { isSuccessAvailableMemberStatuses ?
                  <Select allowClear placeholder='Ընտրել' fieldNames={{ label: 'name', value: 'id' }} options={availableMemberStatuses} />
                  : <LoadingOutlined />
                }
              </Form.Item>
            </Col>
          </Row>

        </Col>
        <Col span={4} style={{ verticalAlign: "middle" }}>
          <div style={{display: 'flex', flexDirection: 'column', height: '80%', justifyContent: 'center', alignItems: 'center', backgroundColor: '#FAFAFA'}}>
            <div className={'users-count users-count_lg'}>
              <div className='users-count__icon'>
                <UserOutlined style={{fontSize: 46, marginBottom: 16}} />
              </div>
              <Spin spinning={isFetchingMembersCount} size='small'>
                <div className='users-count__value'>{allMembersCount}</div>
                <div className='users-count__title'>ՔՊ անդամ</div>
              </Spin>
            </div>
          </div>
        </Col>
      </Row>
      <Flex justify='flex-end'><Button type='primary' htmlType="submit">Կիրառել</Button> </Flex>
    </>
  )
}