import { api } from "../../api";

export const sexApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableSexes: builder.query({
      query: () => ({
        url: 'sexes',
        method: 'GET',
      }),
      transformResponse: (returnValue: any, meta) => {
        return returnValue.map((value: any) => ({ id: String(value.id), name: value.name }))
      }
    })
  })
})

export const { useGetAvailableSexesQuery } = sexApi;