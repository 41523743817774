import { api } from "../../api";

export const adminsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAdmins: builder.query({
      query: () => ({
        url: 'admin/all',
        method: 'GET',
      }),
      providesTags: ['Admins'],
    }),
    addAdmin: builder.mutation({
      query: (data) => ({
        url: 'admin',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Admins'],
    }),
    editAdmin: builder.mutation({
      query: ({adminId, data}) => ({
        url: `admin/${adminId}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Admins'],
    }),
    deleteAdmin: builder.mutation({
      query: (adminId) => ({
        url: `admin/${adminId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Admins'],
    }),
    changePassword: builder.mutation({
      query: (data) => ({
        url: `admin/change-password`,
        method: 'PATCH',
        body: data,
      }),
    }),
  })
})

export const {
  useGetAdminsQuery,
  useAddAdminMutation,
  useEditAdminMutation,
  useDeleteAdminMutation,
  useChangePasswordMutation,
} = adminsApi