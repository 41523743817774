import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Input,
  message,
  Spin,
  Alert,
  Row,
  Col,
  Space,
} from 'antd';
import {EyeOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import UploadAvatarFormItem from "../../../components/UploadAvatarFormItem";
import { useEditAdminMutation, useAddAdminMutation } from "../adminsApi";

const emailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export default function UpdateForm({ adminData = null, closeModal }) {
  const [form] = Form.useForm();
  const [errMsg, setErrMsg] = useState(null);

  const [addAdmin, {isLoading: isLoadingAddAdmin, isSuccess: isSuccessAddAdmin, isError: isErrorAddAdmin, error: errorAddAdmin}] = useAddAdminMutation();
  const [editAdmin, {isLoading: isLoadingEditAdmin, isSuccess: isSuccessEditAdmin, isError: isErrorEditAdmin, error: errorEditAdmin}] = useEditAdminMutation();


  const onFormSubmit = (data) => {
    if (adminData === null) {
      addAdmin(data);
    } else {
      editAdmin({ adminId: adminData.id, data });
    }
  };

  useEffect(() => {
    if (isErrorAddAdmin || isErrorEditAdmin) {
      setErrMsg(errorAddAdmin || errorEditAdmin);
    } else {
      if (isSuccessAddAdmin || isSuccessEditAdmin) {
        message.success(adminData === null ? 'Օգտատերն ավելացված է' : 'Կատարված փոփոխությունները պահպանված են!');
        closeModal();
      }
    }
  }, [
    isLoadingAddAdmin,
    isLoadingEditAdmin,
    isErrorAddAdmin,
    isErrorEditAdmin,
    isSuccessAddAdmin,
    isSuccessEditAdmin,
  ]);

  return (
    <Spin spinning={isLoadingAddAdmin || isLoadingEditAdmin}>
      <Form
        layout='vertical'
        className='ccp-form-items'
        form={form}
        onFinish={onFormSubmit}
        initialValues={adminData ? {
          firstName: adminData.firstName,
          lastName: adminData.lastName,
          emailAddress: adminData.emailAddress,
          image: adminData.image ? { name: adminData.image } : null,
          password: null,
          passwordConfirm: null,
        } : null}
      >
        <Row gutter={24} wrap={true}>
          <Col span={11}>
            <Form.Item name='image'>
              <UploadAvatarFormItem buttonLabel={adminData === null ? 'Վերբեռնել նկար' : 'Փոխել նկարը'} />
            </Form.Item>
          </Col>

          <Col span={13}>
            <Row gutter={18}>
              <Col span={12}>
                <Form.Item
                  name='firstName'
                  label='Անուն'
                  rules={[
                    { required: true, message: 'Մուտքագրեք անունը' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name='lastName'
                  label='ազգանուն'
                  rules={[
                    { required: true, message: 'Մուտքագրեք ազգանունը' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name='emailAddress'
                  label='էլ. հասցե'
                  rules={[
                    { required: true, message: 'Մուտքագրեք էլ. հասցեն' },
                    { pattern: emailRegExp, message: 'Սխալ Էլ. հասցե' },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name='password'
                  label='ԳԱՂՏՆԱԲԱՌ'
                  rules={[
                    ... adminData === null ? [{ required: true, message: 'Մուտքագրեք գաղտնաբառը' }] : [],
                  ]}
                >
                  <Input.Password
                    iconRender={
                      (visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)
                    }
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name='passwordConfirm'
                  label='հաստատել ԳԱՂՏՆԱԲԱՌը'
                  dependencies={['password']}
                  required={adminData === null}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(_, confirmPassword) {
                        const password = getFieldValue('password');
                        if (password && !confirmPassword) {
                          return Promise.reject(new Error('Հաստատեք գաղտնաբառը'));
                        }
                        if (!confirmPassword || password === confirmPassword) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Մուտքագրված գաղտնաբառերը չեն համընկնում'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    iconRender={
                      (visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)
                    }
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            {errMsg
              && <Alert
                message='Տեղի ունեցավ սխալ'
                description={errMsg}
                type='error'
                style={{ marginBottom: '2rem' }}
              />
            }
          </Col>

          <Col span={24}>
            <Space direction="horizontal" style={{width: '100%', justifyContent: 'flex-end'}}>
              <Button
                type='default'
                onClick={closeModal}
              >
                Չեղարկել
              </Button>
              <Button
                type='primary'
                loading={isLoadingAddAdmin || isLoadingEditAdmin}
                htmlType='submit'
              >
                {adminData === null ? 'Ավելացնել' : 'Պահպանել'}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
