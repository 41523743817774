import React from "react";
import {Row, Col, Avatar, Button, Flex, Result, Skeleton, Space} from "antd";
import { useParams, useNavigate, Link } from "react-router-dom";
import { UserOutlined, EnvironmentOutlined, EditOutlined, DownloadOutlined, ExportOutlined } from "@ant-design/icons";
import {useGetMemberQuery} from "../membersApi";
import { formatDate, formatBytes } from '../../../lib/utils';
import MemberStatusTag from '../../../components/MemberStatusTag';
import './styles.scss';
import DocumentIcon from '../../documents/DocumentIcon';
import { useGetAuthProfileQuery } from '../../auth/authApi';

export default function MemberScreen() {
  const navigate = useNavigate();
  const { memberId } = useParams();

  const {data: member, isSuccess: isSuccessMember, error: errorMember} = useGetMemberQuery(memberId);

  const { data: authProfile } = useGetAuthProfileQuery(undefined);
  const isNotMember = authProfile.role !== 'Member';

  if (isSuccessMember && member) {
    return (
      <>
        <Row gutter={24}>
          <Col flex='none'>
            <Avatar icon={<UserOutlined />} size={400} shape='square' src={member.image} />
          </Col>
          <Col flex='1'>
            <Row gutter={28} className='member-info-preview'>
              <Col span={24}>
                <Space style={{marginBottom: 16}} size={14}>
                  <h1 className='member-fullname'>{[member?.lastName, member?.firstName, member?.middleName].filter(Boolean).join(' ')}</h1>
                  <MemberStatusTag status={member?.status} />
                </Space>
                <div className='member-region-tc'><Region region={member.region?.name} territorialCenter={member.territorialCenter?.name} /></div>
              </Col>
              <Col span={12} style={{ paddingRight: "2rem" }}>
                <div className='member-info-line'><span className='label'>Ծննդյան օր</span><span className='value'>{formatDate(member.birthDate)}</span></div>
                <div className='member-info-line'><span className='label'>Սեռ</span><span className='value'>{member.sex?.name}</span></div>
                <div className='member-info-line'><span className='label'>Դիմումի լրացման օր</span><span className='value'>{formatDate(member.dateOfApplicationCompletion)}</span></div>
                <div className='member-info-line'><span className='label'>Կուսակցական դառնալու օր</span><span className='value'>{formatDate(member.dateOfBecomingPartyMember)}</span></div>
                <div className='member-info-line'><span className='label'>Կուսակցական պաշտոն</span><span className='value'>{member.positionInTheParty?.name}</span></div>
                <div className='member-info-line'><span className='label'>Մասնագիտական տվյալներ</span><span className='value'>{member.professionEmployment}</span></div>
                <div className='member-info-line'><span className='label'>Անձնագրային տվյալներ</span><span className='value'>{member.passport}</span></div>
              </Col>
              <Col span={12} className='member-info-col' style={{ paddingLeft: "2rem" }}>
                <div className='member-info-line'><span className='label'>Հեռախոս</span><span className='value'>{member.phoneNumber}</span></div>
                <div className='member-info-line'><span className='label'>Էլ. հասցե</span><span className='value'>{member.emailAddress}</span></div>
                <div className='member-info-line'><span className='label'>Նկատողություններ</span><span className='value'>{member.remark ? "Այո" : "Ոչ"}</span></div>
                {member.attachedFiles.length > 0 ?
                  <div className='member-info-block'>
                    <div className='attached-files'>
                      <div className='attached-files__caption'>
                        <span>կցված Ֆայլեր</span>
                      </div>
                      <div className='attached-files__list'>
                        {member.attachedFiles.map((file: any) => {
                          return (
                            <div
                              className={'file-item'}
                              key={file.id}
                            >
                              <Link to={`${process.env.REACT_APP_API_BASE_URL}/member/attached-file/${file.name}`} target='_blank' className='file-item__type'>
                                <DocumentIcon fileName={file.originalName.toLowerCase()} />
                              </Link>
                              <Link to={`${process.env.REACT_APP_API_BASE_URL}/member/attached-file/${file.name}`} target='_blank' className='file-item__body'>
                                <div className='file-item__name'>{file.originalName}</div>
                                <div className='file-item__size'>{formatBytes(file.size)}</div>
                              </Link>

                              <div className='file-item__action'>
                                <Link to={`${process.env.REACT_APP_API_BASE_URL}/member/attached-file/${file.name}`} target='_blank'>
                                  <ExportOutlined />
                                </Link>
                                <Link to={`${process.env.REACT_APP_API_BASE_URL}/member/download-attached-file/${file.name}`} download={file.originalName}>
                                  <DownloadOutlined />
                                </Link>
                              </div>
                            </div>
                          )
                          })}
                      </div>
                    </div>
                  </div>
                  : null
                }
                <div className='member-info-line member-info-line_vertical'>
                  <span className='label'>Նշումներ</span>
                  <div className='value'>{member.notes}</div>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {isNotMember && <Flex className='member-info-footer' justify='flex-end'><Button type='primary' icon={<EditOutlined />} onClick={() => {navigate(`edit`)}}>Խմբագրել</Button></Flex>}
      </>
    )
  } else {
    if (errorMember) {
      return (
        <Result
          status="500"
          title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      )
    }
  }

  return <div><Skeleton /></div>
}


const Region = ({region, territorialCenter}: {region: string, territorialCenter: string}) => {
  return (
    <><EnvironmentOutlined /> <span>{territorialCenter}{territorialCenter && ', '}{region}</span></>
  )
}