import React from "react";
import { useGetAuthProfileQuery } from "./authApi";
import { Flex } from "antd";
import logo from "../../assets/pics/logo-lg.png";

interface AuthContextType {
  isLoggedIn: boolean;
  role: string;
}

export const AuthContext = React.createContext<AuthContextType>(null!);

export function AuthProvider({ children }: { children: React.ReactNode }) {

  const {isLoading: isLoadingProfile, isSuccess: isSuccessProfile, data: authProfile} = useGetAuthProfileQuery(undefined);

  let value = { isLoggedIn: isSuccessProfile, role: authProfile?.role };

  if (isLoadingProfile) {
    return <Flex vertical style={{ background: '#0b0146', color: '#FFF', height: '100vh' }} justify='center' align='center'><img src={logo} alt='logo' /><div>Բեռնում.....</div></Flex>
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}