import { api } from "../../api";

export interface User {
  first_name: string
  last_name: string
}

export interface UserResponse {
  user: User
  token: string
}

export interface LoginRequest {
  email: string
  password: string
}

export const authApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<UserResponse, LoginRequest>({
      query: (credentials) => ({
        url: 'auth/login',
        method: 'POST',
        body: credentials,
      }),
      invalidatesTags: (result, error) => error ? [] : ['Login'],
    }),
    logout: builder.mutation({
      query: () => ({
        url: 'auth/logout',
        method: 'POST',
      }),
    }),
    getAuthProfile: builder.query({
      query: () => ({
        url: 'auth/profile',
        method: 'GET',
      }),
      providesTags: ['Login'],
    }),
  })
})

export const {
  useLoginMutation,
  useGetAuthProfileQuery,
  useLogoutMutation,
} = authApi