import { api } from "../../api";

export const documentsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getFoldersStructure: builder.query({
      query: () => ({
        url: 'documents/folders-structure',
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    getFolderInfo: builder.query({
      query: ({belongsTo, folderId}) => ({
        url: `documents/folder-info/${belongsTo}/${folderId}`,
        method: 'GET',
      }),
      providesTags: ['Documents'],
    }),
    addDocument: builder.mutation({
      query: ({belongsTo, folderId, file}) => ({
        url: `/documents/file/${belongsTo}/${folderId}`,
        method: 'POST',
        body: { file },
      }),
      invalidatesTags: ['Documents'],
    }),
    deleteDocument: builder.mutation({
      query: (documentId) => ({
        url: `documents/file/${documentId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Documents'],
    }),
  })
})

export const {
  useGetFoldersStructureQuery,
  useGetFolderInfoQuery,
  useAddDocumentMutation,
  useDeleteDocumentMutation,
} = documentsApi;