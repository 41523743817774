import {api} from "../../api";

export const positionsInThePartyApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAvailablePositionsInTheParty: builder.query({
      query: () => ({
        url: 'positions-in-the-party',
        method: 'GET',
      }),
      transformResponse: (returnValue: any, meta) => {
        return returnValue.map((value: any) => ({ id: String(value.id), name: value.name }))
      }
    })
  })
})

export const { useGetAvailablePositionsInThePartyQuery } = positionsInThePartyApi;