import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Pagination, Table, Avatar, Flex, Tag, Spin } from "antd";
import { UserOutlined, UserAddOutlined, LoadingOutlined } from "@ant-design/icons";
import type { ColumnsType } from "antd/es/table";
import useMembersFilters from "../useMembersFilters";
import { AdvancedSearchForm } from "../AdvancedSearchForm";
import { useGetMembersCSVQuery, useGetMembersCountQuery, useGetMembersQuery } from "../membersApi";
import './styles.css';
import MemberStatusTag from "../../../components/MemberStatusTag";
import DownloadXLS from '../../../components/DownloadXLS';
import { useGetAuthProfileQuery } from '../../auth/authApi';

interface MemberType {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  image: string;
  region_id: number;
  region_name: string;
  sex_id: number;
  sex_name: string;
  status_id: number;
  status_name: string;
  territorialCenter_id: number;
  territorialCenter_name: string;
}

const columns: ColumnsType<MemberType> = [
  {
    title: '',
    dataIndex: 'image',
    key: 'image',
    width: '4.4%',
    render: (text) => <div className='table-member__img'><Avatar size={48} shape='square' icon={<UserOutlined />} src={text} draggable /></div>
  },
  {
    title: 'Ազգանուն անուն հայրանուն',
    key: 'name',
    render: (_, record) => [record.lastName, record.firstName, record.middleName].filter(Boolean).join(' '),
  },
  {
    title: 'Սեռ',
    dataIndex: 'sex_name',
    key: 'sex_name',
    render: (sexName) => <div>{sexName}</div>,
  },
  {
    title: 'Մարզ',
    dataIndex: 'region_name',
    key: 'region_name',
    render: (regionName) => <div>{regionName}</div>,
  },
  {
    title: 'Տարածքային կազմակերպություն',
    key: 'territorialCenter_name',
    dataIndex: 'territorialCenter_name',
    render: (territorialCenterName) => <div>{territorialCenterName}</div>,
  },
  {
    title: 'Կարգավիճակ',
    key: 'status_name',
    dataIndex: 'status_name',
    render: (statusName, record) => <MemberStatusTag status={{id: record.status_id, name: record.status_name}} />,
  },
];

export default function MembersScreen() {
  const navigate = useNavigate();

  const { page, pageSize, filters, setPage, setPageSize, INITIAL_PAGESIZE } = useMembersFilters();

  const { data: authProfile } = useGetAuthProfileQuery(undefined);
  const isNotMember = authProfile.role !== 'Member';

  const { isFetching: isFetchingMembersCount, data: allMembersCount } = useGetMembersCountQuery(filters);

  const { isFetching: isFetchingMembers, data: members } = useGetMembersQuery({
    page,
    pageSize,
    ...filters,
  });

  const { isFetching: isFetchingMembersCSV, data: membersData } = useGetMembersCSVQuery({
    ...filters,
  });

  return (
    <>
      <AdvancedSearchForm
        allMembersCount={allMembersCount}
        headerRightElements={
          <>
            <DownloadXLS disabled={isFetchingMembersCSV} data={membersData} fileName='Members' />
            {isNotMember && <Button type='primary' onClick={() => { navigate('/member/add') }} icon={<UserAddOutlined />}>Ավելացնել</Button>}
          </>
        }
      />
      <br />
      <br />
      <Table
        bordered
        sticky
        loading={isFetchingMembers}
        locale={{...(isFetchingMembers && {emptyText: 'Տվյալները բեռնվում են'})}}
        columns={columns}
        dataSource={members}
        rowKey={(record) => record.id}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            navigate(`/member/${record.id}`)
          }
        })}
        className='members-list table-member'
      />
      <br />
      <br />
      <Flex justify='space-between' className='members-footer' align='center'>
        <Spin spinning={isFetchingMembersCount} size='small'>
          <div className={'members-footer__count users-count users-count_sm'}>
            <div className='users-count__icon'>
              <UserOutlined style={{fontSize: 46, marginBottom: 16}} />
            </div>
            <Spin spinning={isFetchingMembersCount} size='small'>
              <div className='users-count__value'>{allMembersCount}</div>
              <div className='users-count__title'>ՔՊ անդամ</div>
            </Spin>
          </div>
        </Spin>
        <Spin spinning={isFetchingMembersCount} size='small'>
          <Pagination
            current={page}
            defaultPageSize={pageSize}
            total={allMembersCount || 0}
            pageSizeOptions={[INITIAL_PAGESIZE, 10, 20, 30, 40, 50, 75, 100]}
            onChange={(clickedPage, clickedPageSize) => {
              if (clickedPage !== page) {
                setPage(clickedPage);
              }
              if (clickedPageSize !== pageSize) {
                setPageSize(clickedPageSize);
              }
            }}
          />
        </Spin>
      </Flex>
    </>
  )
}