import { api } from "../../api";

export const regionApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableRegions: builder.query({
      query: () => ({
        url: 'regions',
        method: 'GET',
      }),
      transformResponse: (returnValue: any, meta) => {
        return returnValue.map((value: any) => ({ id: String(value.id), name: value.name }))
      }
    }),
    getAvailableTerritorialCenters: builder.query({
      query: () => ({
        url: 'regions/territorial-centers',
        method: 'GET',
      }),
      transformResponse: (returnValue: any, meta) => {
        return returnValue.map((value: any) => ({ id: String(value.id), name: value.name }))
      }
    }),
  })
})

export const { useGetAvailableRegionsQuery, useGetAvailableTerritorialCentersQuery } = regionApi;