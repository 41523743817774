import React, { useCallback, useEffect, useState } from 'react';
import {Upload, Button, Avatar, Space} from 'antd';
import {DeleteOutlined, UploadOutlined, UserOutlined} from '@ant-design/icons';
import './style.css';

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

const UploadAvatarFormItem = ({ value, onChange, buttonLabel, handleRemoveAvatar }) => {
  const [previewImage, setPreviewImage] = useState(null);
  const [errMsg, setErrMsg] = useState([]);

  const beforeUpload = (file) => {
    const errors = [];
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      errors.push('Կարելի է ներբեռնել միայն \'JPG/JPEG/PNG\' ֆորմատի ֆայլեր:');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      errors.push('Նկարը չպետք է լինի 2 Մբ-ից մեծ:');
    }
    if (errors.length > 0) {
      setErrMsg(errors);
    }
    return isJpgOrPng && isLt2M;
  };

  const handleAvatarChange = useCallback(({ file }) => {
    if (file.status === 'done' || file.status === 'uploading') {
      getBase64(file.originFileObj, (imageURL) => {
        setPreviewImage(imageURL);
        if ('response' in file && !('error' in file.response) && onChange) {
          onChange(file.response);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (value && 'name' in value && !('machineName' in value)) {
      setPreviewImage(value.name);
    }
  }, [value]);

  // const fakeCustomRequest = useCallback(({ onSuccess }) => setTimeout(() => onSuccess('ok')), []);

  return (
    <Upload
      showUploadList={false}
      className={`upload-avatar-form-item ${previewImage ? 'preview-mode' : ''}`}
      onChange={handleAvatarChange}
      beforeUpload={beforeUpload}
      action={`${process.env.REACT_APP_API_BASE_URL}/file/avatar`}
      accept='image/png, image/jpeg'
      withCredentials
    >
      <Space direction='vertical' size={44} style={{width: '100%'}}>
        <Avatar
          shape="square"
          size={266}
          icon={<UserOutlined />}
          src={previewImage}
          style={{border: errMsg.length > 0 ? 'solid 1px red' : 'solid 1px white', width: '100%', height: '100%'}}
        />
        {errMsg.length > 0
          ? errMsg.map((msg, index) => (
            <span
              key={index}
              style={{
                color: 'red',
                marginTop: 2,
                maxWidth: '16rem',
              }}
            >
            {msg}
          </span>
          ))
          : null
        }
        <Button
          type='default'
          icon={<UploadOutlined />}
          block
        >
          {buttonLabel || 'Վերբեռնել նկար'}
        </Button>
      </Space>
      {previewImage  && <Button
        type='default'
        danger
        icon={<DeleteOutlined />}
        style={{ marginTop: '24px' }}
        block
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          
          setPreviewImage(null);
          handleRemoveAvatar();
        }
      }
      >
        {'Ջնջել նկարը'}
      </Button>}
    </Upload>
  );
};

export default UploadAvatarFormItem;
