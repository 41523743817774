import {Table, Button, Avatar, Flex, Space, Modal, Popconfirm} from "antd";
import {UserOutlined, EditOutlined, DeleteOutlined, UserAddOutlined} from "@ant-design/icons";
import {ColumnsType} from "antd/es/table";
import {useGetAdminsQuery, useDeleteAdminMutation} from "../adminsApi";
import {useMemo, useState} from "react";
import UpdateForm from "../UpdateForm";

interface AdminType {
  id: number;
  firstName: string;
  lastName: string;
  middleName: string;
  image: string;
  role: string,
  sex_id: number;
  sex_name: string;
  territorialCenter_id: number;
  territorialCenter_name: string;
}

export default function AdminsScreen() {
  const [selectedAdminId, setSelectedAdminId] = useState<number | null>(null)
  const {isLoading: isLoadingAdmins, data: admins,} = useGetAdminsQuery(undefined);

  const [deleteAdmin, {isLoading: isLoadingDeleteAdmin}] = useDeleteAdminMutation();

  const columns: ColumnsType<AdminType> = useMemo(() => {
    return [
      {
        title: '',
        dataIndex: 'image',
        width: '4.4%',
        key: 'image',
        render: (text) => <div className='table-member__img'><Avatar size={48} shape='square' icon={<UserOutlined />} src={text} /></div>
      },
      {
        title: 'Անուն',
        key: 'fullName',
        render: (_, record) => [record.firstName, record.lastName, record.middleName].filter(Boolean).join(' '),
      },
      {
        title: 'էլ. հասցե',
        dataIndex: 'emailAddress',
        key: 'email',
        // render: (text) => <div>{text}</div>,
      },
      {
        title: 'Գործողություններ',
        dataIndex: 'action',
        key: 'action',
        align: 'right',
        render: (text, record) => (
          <Space>
            <Button
              icon={<EditOutlined />}
              onClick={() => { setSelectedAdminId(record.id); }}
              type='primary'
              className='admins-edit'
              ghost
            />
            {record.role === 'SuperAdmin'
              ? <Button
                icon={<DeleteOutlined />}
                disabled
                type='primary'
                className='admins-delete'
                danger
                ghost
              />
              : <Popconfirm
                title='Հեռացնել օգտատիրոջը'
                description="Վստահ ե՞ք, որ ցանկանում եք հեռացնել այս օգտատիրոջը"
                okText="Հեռացնել"
                okType='primary'
                okButtonProps={{ danger: true }}
                cancelText="Չեղարկել"
                onConfirm={() => { deleteAdmin(record.id) } }
              >
                <Button
                  icon={<DeleteOutlined />}
                  type='primary'
                  className='admins-delete'
                  danger
                  ghost
                />
              </Popconfirm>
            }
          </Space>
        ),
      },
    ]
  }, [])

  return (
    <>
      <Flex justify='space-between' align='center' style={{ marginBottom: '2rem' }}>
        <h1>Օգտատերերի կառավարում</h1>
        <Button icon={<UserAddOutlined />} type='primary' onClick={() => { setSelectedAdminId(0) }}>Ավելացնել</Button>
      </Flex>
      <Table
        bordered
        sticky
        loading={isLoadingAdmins}
        locale={{...(isLoadingAdmins ? {emptyText: 'Տվյալները բեռնվում են'} : {})}}
        columns={columns}
        dataSource={admins}
        rowKey={(record) => record.id}
        pagination={false}
        className='table-member'
      />

      <Modal
        open={selectedAdminId !== null}
        title={selectedAdminId === 0 ? 'Ավելացնել օգտատեր' : 'Փոփոխել օգտատիրոջ տվյալները'}
        footer={false}
        closable={false}
        width='57.5rem'
        destroyOnClose
      >
        <UpdateForm
          adminData={(selectedAdminId !== null && selectedAdminId !== 0) ? admins.find((admin: any) => admin.id === selectedAdminId) : null}
          closeModal={() => { setSelectedAdminId(null) }}
        />
      </Modal>
    </>
  )
}