import { api } from "../../api";

export const membersApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getMembers: builder.query({
      query: (params) => ({
        url: 'member',
        method: 'GET',
        params: params,
      }),
      providesTags: ['Members'],
    }),
    getMembersCSV: builder.query({
      query: (params) => ({
        url: 'member/export-csv',
        method: 'GET',
        params: params,
      }),
    }),
    getMembersCount: builder.query({
      query: (params) => ({
        url: 'member/count',
        method: 'GET',
        params: params,
      })
    }),
    getMember: builder.query({
      query: (memberId) => ({
        url: `member/${memberId}`,
        method: 'GET'
      }),
      providesTags: ['Member'],
    }),
    addMember: builder.mutation({
      query: (data) => ({
        url: 'member',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Members'],
    }),
    editMember: builder.mutation({
      query: ({memberId, values}) => ({
        url: `member/${memberId}`,
        method: 'PATCH',
        body: values,
      }),
      invalidatesTags: ['Members', 'Member'],
    }),
  })
})

export const {
  useGetMembersQuery,
  useGetMembersCSVQuery,
  useGetMembersCountQuery,
  useGetMemberQuery,
  useAddMemberMutation,
  useEditMemberMutation,
} = membersApi