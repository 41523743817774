import {Tag} from "antd";

type Status = {
  id: number;
  name: string;
}

export default function MemberStatusTag({ status }: { status: Status | null }) {
  const colors: any = {
    '1': 'green',
    '2': 'magenta',
    '3': 'lime',
  }

  return !status || !status?.id
    ? null
    : <Tag color={colors[status.id]}>{status.name}</Tag>
}