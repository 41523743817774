import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";

export default function useMembersFilters() {
  const [searchParams, setSearchParams] = useSearchParams();

  const INITIAL_PAGE = 1;
  const INITIAL_PAGESIZE = 30;

  const AVAILABLE_FILTERS_FIELDS = useMemo(() => {
    return {
      fullName:                         { multiValue: false, extended: false, label: 'Անուն, ազգանուն' },
      phoneNumber:                      { multiValue: false, extended: false, label: 'Հեռախոս' },
      emailAddress:                     { multiValue: false, extended: false, label: 'Էլ. հասցե' },
      regions:                          { multiValue: true,  extended: true,  label: 'մարզ' },
      territorialCenters:               { multiValue: true,  extended: true,  label: 'տարածքային կազմակերպություն' },
      sex:                              { multiValue: false, extended: true,  label: 'սեռ' },
      profession:                       { multiValue: false, extended: true,  label: 'մասնագիտություն' },
      startDateOfApplicationCompletion: { multiValue: false, extended: true,  label: 'դիմումի լրացման օր' },
      endDateOfApplicationCompletion:   { multiValue: false, extended: true,  label: 'դիմումի լրացման օր' },
      startDateOfBecomingPartyMember:   { multiValue: false, extended: true,  label: 'կուսակցական դառնալու օր' },
      endDateOfBecomingPartyMember:     { multiValue: false, extended: true,  label: 'կուսակցական դառնալու օր' },
      employment:                       { multiValue: false,  extended: true,  label: 'զբաղվածություն' },
      positionInTheParty:               { multiValue: true,  extended: true,  label: 'կուսակցական պաշտոն' },
      remark:                           { multiValue: false, extended: true,  label: 'նկատողություններ' },
      status:                           { multiValue: false,  extended: true,  label: 'կարգավիճակ' },
    }
  }, [])

  const setPage = (newPage: number) => {
    searchParams.set('page', String(newPage));
    setSearchParams(searchParams);
  }

  const setPageSize = (newPageSize: number) => {
    searchParams.set('pageSize', String(newPageSize));
    setSearchParams(searchParams);
  }

  const setFilters = (newFilters: any = {}) => {
    for (const [filterField, filterOptions] of Object.entries(AVAILABLE_FILTERS_FIELDS)) {
      if (
        newFilters.hasOwnProperty(filterField)
        &&
        (
          (filterOptions.multiValue && Array.isArray(newFilters[filterField]) && newFilters[filterField].length > 0)
          ||
          (!filterOptions.multiValue && String(newFilters[filterField] ?? '').trim())
        )
      ) {
        searchParams.set(filterField, newFilters[filterField]);
      } else if (searchParams.has(filterField)) {
        searchParams.delete(filterField);
      }
    }
    setSearchParams(searchParams);
  }

  const removeFilters = (filtersName: string[] = []) => {
    for (const filterName of filtersName) {
      if (searchParams.has(filterName)) {
        searchParams.delete(filterName);
      }
    }
    setSearchParams(searchParams);
  }

  const params = useMemo(() => {
    let page = INITIAL_PAGE;
    let pageSize = INITIAL_PAGESIZE;
    const filters: any = {};

    const page_unsafe = searchParams.get('page');
    if (page_unsafe !== null && page_unsafe !== '' && /^\+?[1-9]\d*$/.test(page_unsafe)) {
      page = Number(page_unsafe);
    }

    const pageSize_unsafe = searchParams.get('pageSize');
    if (pageSize_unsafe !== null && pageSize_unsafe !== '' && /^\+?[1-9]\d*$/.test(pageSize_unsafe)) {
      pageSize = Number(pageSize_unsafe);
    }

    for (const [filterField, filterOptions] of Object.entries(AVAILABLE_FILTERS_FIELDS)) {
      const value_unsafe = searchParams.get(filterField);
      if (value_unsafe !== null && value_unsafe !== '') {
        if (filterOptions.multiValue) {
          const value_unsafe_arr = value_unsafe.split(',');
          if (value_unsafe_arr.length > 0) {
            filters[filterField] = value_unsafe_arr;
          }
        }else {
          filters[filterField] = value_unsafe;
        }
      }
    }

    return { page, pageSize, filters }
  }, [searchParams.toString()])

  return {
    page: params.page,
    pageSize: params.pageSize,
    filters: params.filters,

    setPage,
    setPageSize,
    setFilters,
    removeFilters,

    INITIAL_PAGESIZE,
    AVAILABLE_FILTERS_FIELDS,
  }
}
