import {Outlet} from "react-router-dom";
import React from "react";
import { Layout, Row, Col, Grid } from "antd";
import logoLarge from '../../assets/pics/logo-lg.png';
import './styles.css';

const { useBreakpoint } = Grid;

export default function UnsignedLayout() {
  const { xs } = useBreakpoint();
  // console.log(xs);
  return (
    <Layout>
      <Layout.Content>
        <Row align={xs ? 'top' : 'middle'} style={{height: '100%', textAlign: 'center'}}>
          <Col
            span={24} sm={8} md={10} lg={12}
            style={{
              backgroundImage: `url(${logoLarge})`,
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'initial',
              backgroundColor: '#0b0146',
              height: xs ? 180 : '100%',
            }}
          />
          <Col span={24} sm={16} md={14} lg={12}><Outlet /></Col>
        </Row>
      </Layout.Content>
    </Layout>
  );
}