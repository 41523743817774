import { Link } from "react-router-dom";
import { Table, Tooltip } from "antd";
import { FolderFilled } from "@ant-design/icons";
import { formatBytes, formatDate } from '../../../lib/utils';
import './styles.css';
import {useGetFoldersStructureQuery} from "../documentsApi";

const columns = [
  {
    title: '',
    dataIndex: 'name',
    key: 'name',
    render: (text: string, record: any) => {
      if ('children' in record) {
        return <span className='section-name'>{text}</span>;
      }
      return <Link to={`belongs_${record.type}/${record.id}`} className='folder-link'><FolderFilled style={{fontSize: 24, color: '#ffda79'}} />{text}</Link>;
    },
  },
  {
    title: 'Ֆայլերի քանակ',
    dataIndex: 'docsCount',
    key: 'docsCount',
  },
  /*{
    title: 'Ֆայլերի չափս',
    dataIndex: 'docsSize',
    key: 'docsSize',
    render: (text: string) => formatBytes(text),
  },*/
  {
    title: 'Վերջին ավելացում',
    dataIndex: 'lastCreation',
    key: 'lastCreation',
    render: (text: string, record: any) => ('children' in record ? null : <Tooltip title={formatDate(text)}>{formatDate(text, true)}</Tooltip>),
  },
];

export default function DocumentsFoldersStructureScreen() {

  const { isLoading: isLoadingFoldersStructure, data: folderStructure } = useGetFoldersStructureQuery(undefined);

  return (
    <Table
      sticky
      loading={isLoadingFoldersStructure}
      locale={{emptyText: 'Տվյալները բեռնվում են'}}
      className='documents-folder-structure'
      key={folderStructure}
      columns={columns}
      dataSource={folderStructure || []}
      rowKey={(record) => `${record.id}-${record.name}`}
      pagination={false}
      expandable={{
        defaultExpandAllRows: true,
        expandRowByClick: true,
      }}
    />
  )
}