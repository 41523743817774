import { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Input,
  message,
  Spin,
  Alert,
  Row,
  Col,
  Space,
} from 'antd';
import {EyeOutlined, EyeInvisibleOutlined} from "@ant-design/icons";
import { useChangePasswordMutation } from '../../../features/admins/adminsApi';

export default function ChangePassForm({ closeModal }) {
  const [form] = Form.useForm();
  const [errMsg, setErrMsg] = useState(null);

  const [changePassword, {isLoading, isSuccess, isError, error}] = useChangePasswordMutation();


  const onFormSubmit = (data) => {
    changePassword(data);
  };

  useEffect(() => {
    if (isError) {
      let errorMsg = '';
      if(typeof error === 'string') {
        errorMsg = error;
      } else {
        errorMsg = error?.data?.message;
      }
      
      setErrMsg(errorMsg || "Անհայտ Սխալ");
    } else {
      if (isSuccess) {
        message.success('Կատարված փոփոխությունները պահպանված են!');
        closeModal();
      }
    }
  }, [
    isLoading,
    isError,
    isSuccess,
  ]);

  return (
    <Spin spinning={isLoading}>
      <Form
        layout='vertical'
        className='ccp-form-items'
        form={form}
        onFinish={onFormSubmit}
      >
        <Row gutter={24} wrap={true}>
          <Col span={24}>
            <Form.Item
              name='old_password'
              label='ՀԻՆ ԳԱՂՏՆԱԲԱՌ'
              required={true}
              rules={[
                [{ required: true, message: 'Մուտքագրեք հին գաղտնաբառը' }],
              ]}
            >
              <Input.Password
                iconRender={
                  (visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='new_password'
              label='ՆՈՐ ԳԱՂՏՆԱԲԱՌ'
              required={true}
              rules={[
                [{ required: true, message: 'Մուտքագրեք հին գաղտնաբառը' }],
              ]}
            >
              <Input.Password
                iconRender={
                  (visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name='passwordConfirm'
              label='հաստատել ԳԱՂՏՆԱԲԱՌը'
              dependencies={['new_password']}
              required={true}
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, confirmPassword) {
                    const password = getFieldValue('new_password');
                    if (password && !confirmPassword) {
                      return Promise.reject(new Error('Հաստատեք գաղտնաբառը'));
                    }
                    if (!confirmPassword || password === confirmPassword) {
                      return Promise.resolve();
                    }
                    return Promise.reject(new Error('Մուտքագրված գաղտնաբառերը չեն համընկնում'));
                  },
                }),
              ]}
            >
              <Input.Password
                iconRender={
                  (visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            {errMsg
              && <Alert
                message='Տեղի ունեցավ սխալ'
                description={errMsg}
                type='error'
                style={{ marginBottom: '2rem' }}
              />
            }
          </Col>

          <Col span={24}>
            <Space direction="horizontal" style={{width: '100%', justifyContent: 'flex-end'}}>
              <Button
                type='default'
                onClick={closeModal}
              >
                Չեղարկել
              </Button>
              <Button
                type='primary'
                loading={isLoading}
                htmlType='submit'
              >
                {'Պահպանել'}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
