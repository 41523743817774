import {useNavigate, useLocation, Navigate} from "react-router-dom";
import React, {useContext} from "react";
import { Form, Input, Button, Card } from "antd";
import { useLoginMutation } from "../authApi";
import {AuthContext} from "../AuthProvider";

type FieldType = {
  email?: string;
  password?: string;
};

export default function LoginScreen() {
  const navigate = useNavigate();
  let location = useLocation();
  const [form] = Form.useForm();

  const { isLoggedIn } = useContext(AuthContext);

  const [login, result] = useLoginMutation();

  let from = location.state?.from?.pathname || "/";

  const formSubmit = async (values: any) => {
    login({email: values.email, password: values.password});
    /*const error: any | null = await auth.signIn({email: values.email, password: values.password}, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      navigate(from, {replace: true});
    });
    if (error) {
      form.setFields([
        {name: 'email', errors: [error]},
        {name: 'password', errors: ['']},
      ])
    }*/
  }

  if (isLoggedIn) {
    return <Navigate to='/members' replace />
  }

  return (
    <Card title={null} bordered={false} style={{ width: '70%', maxWidth: 400, margin: '0 auto' }}>
      <Form autoComplete="off" layout="vertical" onFinish={formSubmit} form={form}>
        <Form.Item<FieldType>
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item<FieldType>
          label="Password"
          name="password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" block>
            Submit
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
}