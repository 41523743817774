import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

export function formatBytes(bytes, decimals = 1) {
  if (bytes === undefined) return null;
  if (!+bytes) return '0 Bytes';

  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function formatDate(dateString, relative = false) {
  const date = dayjs(dateString);
  let formattedDate = null;
  if (date.isValid()) {
    if (relative) {
      formattedDate = date.fromNow();
    } else {
      formattedDate = date.format('DD/MM/YYYY')
    }
  }
  return formattedDate;
}