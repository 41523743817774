import { api } from '../../api';

export const memberStatusApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAvailableMemberStatus: builder.query({
      query: () => ({
        url: 'member-status',
        method: 'GET',
      }),
      transformResponse: (returnValue: any, meta) => {
        return returnValue.map((value: any) => ({ id: String(value.id), name: value.name }))
      }
    })
  })
})

export const { useGetAvailableMemberStatusQuery } = memberStatusApi;