import {Outlet, useNavigate} from "react-router-dom";
import {Row, Col, Button} from "antd";
import {ArrowLeftOutlined} from "@ant-design/icons";

export default function MemberLayout() {
  const navigate = useNavigate();
  return (
    <Row gutter={40} wrap={false}>
      <Col><Button className='back-btn' icon={<ArrowLeftOutlined />} type='default' onClick={() => {navigate(-1)}} /></Col>
      <Col flex={1}>
        <main className='white-container'>
          <Outlet />
        </main>
      </Col>
    </Row>
  )
}