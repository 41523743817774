import React, { useState } from "react";
import {NavLink, Outlet, useNavigate, useLocation, Link} from "react-router-dom";
import {Divider, Button, Layout, theme, Space, Row, Col, Avatar, Tag, Dropdown, Modal } from "antd";
import { useAppDispatch } from "../../store";
import { api } from "../../api";
import { UserOutlined, DownOutlined } from '@ant-design/icons';
import logoSmall from '../../assets/pics/logo.png';
import './styles.css';
import { useGetAuthProfileQuery, useLogoutMutation } from "../../features/auth/authApi";
import ChangePassForm from './ChangePassForm';
const { useToken } = theme;

export default function SignedLayout() {
  const location = useLocation();

  const [passModalVisible, setPassModalVisible] = useState<boolean>(false);

  return (
    <Layout>
      <Layout.Header style={{ height: 120, color: 'white', backgroundColor: '#0b0146' }}>
        <Row wrap={false} align='middle'>
          <Col span={18}>
            <Row gutter={24} wrap={false}>
              <Col><img src={logoSmall} alt='logo' width={112} height={120} style={{ display: 'block', }} /></Col>
              <Col>
                <h2 className='header-site-name'>Քաղաքացիական Պայմանագիր Կուսակցություն</h2>
                <Space size={20} align='center' className='main-menu'>
                  <Link to='/members' className={`main-menu-link${location.pathname.startsWith('/member') ? ' active' : ''}`}>Անդամներ</Link>
                  <NavLink className='main-menu-link' to='/documents'>Փաստաթղթեր</NavLink>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col span={6} style={{textAlign: "right"}}>
            <AuthStatus setPassModalVisible={setPassModalVisible} />
          </Col>
        </Row>
      </Layout.Header>

      <Layout.Content style={{ padding: '30px 75px' }}>
        <Outlet />
      </Layout.Content>

      <Modal
        open={passModalVisible}
        title={'Փոփոխել Գաղտնաբառը'}
        footer={false}
        closable={false}
        width='57.5rem'
        destroyOnClose
      >
        <ChangePassForm
          closeModal={() => { setPassModalVisible(false) }}
        />
      </Modal>
    </Layout>
  );
}


function AuthStatus({ setPassModalVisible } : { setPassModalVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
  const dispatch = useAppDispatch();
  const { data: authProfile } = useGetAuthProfileQuery(undefined);
  const { token } = useToken();
  const navigate = useNavigate();
  const contentStyle: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary,
  };
  const menuStyle: React.CSSProperties = {
    boxShadow: 'none',
  };

  const [logout, { isLoading: isLoadingLogout }] = useLogoutMutation();

  let items = [
    { key: '1', label: <div onClick={() => setPassModalVisible(true)}>Փոփոխել Գաղտնաբառը</div> },
  ];

  if(authProfile.role === 'SuperAdmin' || authProfile.role === 'Admin') {
    items = [
      ...items,
      { key: '2', label: <NavLink to={'/admins'} rel="noopener noreferrer">Օգտատերերի կառավարում</NavLink> },
    ]
  }

  return (
    <Dropdown
      trigger={['click']}
      menu={{ items }}
      dropdownRender={(menu: any) => {
        return (
          <div style={contentStyle}>
            {menu.props.items.length > 0 &&
              <>
                {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
                <Divider style={{ margin: 0 }} />
              </>
            }
            <div style={{ padding: 8 }}>
              <Button
                loading={isLoadingLogout}
                type="primary"
                block
                onClick={() => {
                  logout(undefined)
                    .then(() => {
                      dispatch(api.util.resetApiState());
                      navigate('/login', {replace: true});
                    })
                }}
              >
                Դուրս գալ համակարգից
              </Button>
            </div>
          </div>
        )
      }}
      arrow
      placement="bottomRight"
    >
      <Space style={{ cursor: 'pointer' }}>
        <Tag className='profile-role' color={`${authProfile.role === 'SuperAdmin' ? 'red': 'green'}`}>{authProfile.role}</Tag>
        <Avatar icon={<UserOutlined />} style={{ backgroundColor: '#87d068' }} src={authProfile.image} />
        <span>{authProfile.firstName}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
}