import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import dayjs from "dayjs";
import { ConfigProvider, theme, ThemeConfig } from "antd";
import locale from 'antd/locale/hy_AM';
import 'dayjs/locale/hy-am';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from "./store";
import './index.scss';


const themeConfig: ThemeConfig = {
  token: {
    // Seed Token
    colorPrimary: '#0b0146',
    controlHeight: 48,
    controlOutline: 'rgb(178,197,255)',
    fontSize: 16,

    // Alias Token
    // colorBgContainer: '#f6ffed',
  },
}

dayjs.locale('hy-am')

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ConfigProvider theme={themeConfig} locale={locale}>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
