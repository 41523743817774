import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import * as XLSX from 'xlsx';

type DownloadCSVProps = {
  data: string[];
  fileName: string;
  disabled: boolean,
}

export default function DownloadXLS({ data, fileName, disabled }: DownloadCSVProps) {
  const downloadExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <Button type='default' className='download-csv' disabled={disabled} icon={<DownloadOutlined />} onClick={downloadExcel}>Download CSV</Button>
  );
}