import React, {useCallback, useMemo} from "react";
import {Link, useNavigate, useParams } from "react-router-dom";
import dayjs from 'dayjs';
import {
  Form,
  Row,
  Col,
  InputNumber,
  Input,
  Radio,
  Select,
  DatePicker,
  Flex,
  Button,
  message,
  Upload,
  Skeleton,
} from "antd";
import { DeleteOutlined, DownloadOutlined, EditOutlined, LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import {useAddMemberMutation, useEditMemberMutation, useGetMemberQuery} from "../membersApi";
import {useGetAvailableSexesQuery} from "../../sex/sexApi";
import {useGetAvailableMemberStatusQuery} from "../../memberStatus/memberStatusApi";
import {useGetAvailableRegionsQuery, useGetAvailableTerritorialCentersQuery} from "../../region/regionApi";
import {useGetAvailablePositionsInThePartyQuery} from "../../positionsInTheParty/positionsInThePartyApi";
import UploadAvatarFormItem from "../../../components/UploadAvatarFormItem";
import MemberStatusTag from "../../../components/MemberStatusTag";
import DocumentIcon from '../../documents/DocumentIcon';
import { formatBytes } from '../../../lib/utils';

export default function AddOrEditMember() {
  const navigate = useNavigate();
  const { memberId } = useParams();
  const [form] = Form.useForm();

  const {data: member, isLoading: isLoadingMember} = useGetMemberQuery(memberId, { skip: !memberId })

  const {data: availableSexes, isSuccess: isSuccessAvailableSexes} = useGetAvailableSexesQuery(undefined);
  const {data: availableMemberStatuses, isSuccess: isSuccessAvailableMemberStatuses} = useGetAvailableMemberStatusQuery(undefined);
  const {data: availableRegions, isSuccess: isSuccessAvailableRegions} = useGetAvailableRegionsQuery(undefined);
  const {data: availableTerritorialCenters, isSuccess: isSuccessAvailableTerritorialCenters} = useGetAvailableTerritorialCentersQuery(undefined);
  const {data: availablePositionsInTheParty, isSuccess: isSuccessAvailablePositionsInTheParty} = useGetAvailablePositionsInThePartyQuery(undefined);

  const [addMember] = useAddMemberMutation();
  const [editMember] = useEditMemberMutation();

  const onFormSubmit = async (values) => {
    if (values.birthDate) {
      values.birthDate = values.birthDate.format('YYYY-MM-DD');
    }
    if (values.dateOfApplicationCompletion) {
      values.dateOfApplicationCompletion = values.dateOfApplicationCompletion.format('YYYY-MM-DD');
    }
    if (values.dateOfBecomingPartyMember) {
      values.dateOfBecomingPartyMember = values.dateOfBecomingPartyMember.format('YYYY-MM-DD');
    }
    /*if (values.phoneNumber) {
      values.phoneNumber = `${values.phoneNumber?.countryCode}${values.phoneNumber?.areaCode}${values.phoneNumber?.phoneNumber}`;
    } else {
      values.phoneNumber = null;
    }*/
    if (values.attachedFiles) {
      const attachedFiles = [];
      values.attachedFiles.forEach((attachedFile) => {
        if ('response' in attachedFile && !('error' in attachedFile.response)) {
          attachedFiles.push(attachedFile.response); // name, machineName, size
        } else if ('id' in attachedFile) {
          attachedFiles.push({
            id: attachedFile.id,
            name: attachedFile.name,
            size: attachedFile.size,
          });
        }
      });
      values.attachedFiles = attachedFiles;
    }

    if (memberId) { // is Edit mode
      const res = await editMember({memberId, values});
      if (!('error' in res) && 'data' in res && 'id' in res.data) {
        message.success('Փոփոխությունները պահպանված են');
      } else {
        message.error('Տեղի ունեցավ սխալ');
        console.log(res);
      }
    } else {
      const res = await addMember(values);
      if (!('error' in res) && 'data' in res && 'id' in res.data) {
        message.success('Գործողությունը հաջողությամբ կատարվեց');
        navigate(`/member/${res.data.id}`, { replace: true })
      } else {
        message.error('Տեղի ունեցավ սխալ');
        console.log(res);
      }
    }

  }

  const formInitialValue = useMemo(() => {
    let values;
    if (member) {
      values = {
        firstName: member.firstName,
        lastName: member.lastName,
        middleName: member.middleName,
        sexId: member.sex?.id ? String(member.sex.id) : null,
        memberStatusId: member.status?.id ? String(member.status.id) : null,
        regionId: member.region?.id ? String(member.region.id) : null,
        territorialCenterId: member.territorialCenter?.id ? String(member.territorialCenter.id) : null,
        birthDate: member.birthDate && dayjs(member.birthDate),
        dateOfApplicationCompletion: member.dateOfApplicationCompletion && dayjs(member.dateOfApplicationCompletion),
        dateOfBecomingPartyMember: member.dateOfBecomingPartyMember && dayjs(member.dateOfBecomingPartyMember),
        professionEmployment: member.professionEmployment,
        passport: member.passport,
        positionInThePartyId: member.positionInTheParty?.id ? String(member.positionInTheParty.id) : null,
        phoneNumber: member.phoneNumber,
        emailAddress: member.emailAddress,
        remark: member.remark ? '1' : '0',
        notes: member.notes,
        image: member.image ? { name: member.image } : null,
        ...(member.attachedFiles.length && {
          attachedFiles: member.attachedFiles.map((file) => (
            {
              uid: String(file.id * -1),
              id: file.id,
              name: file.originalName,
              url: `${process.env.REACT_APP_API_BASE_URL}/attached-file/file/${file.name}`,
              status: 'done',
              size: file.size,
            }
          )),
        }),
      };
    }
    return values;
  }, [member]);

  const handleRemoveAvatar = useCallback(() => {
    if (form.setFieldValue('image')) {
      form.setFieldValue('image', null);
    }
  }, [form])

  if (!memberId || member) {
    return (
      <Form
        form={form}
        layout='vertical'
        className='ccp-form-items'
        initialValues={formInitialValue}
        onFinish={onFormSubmit}
      >
        <Row gutter={18} wrap={false}>
          <Col span={6}>
            <Form.Item name='image'>
              <UploadAvatarFormItem handleRemoveAvatar={handleRemoveAvatar} />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Row gutter={18}>
              <Col span={12} className='member-edit-items' style={{ paddingRight: '2rem' }}>

                <Row gutter={20}>
                  <Col span={24}>ԱՆՁՆԱԿԱՆ ՏՎՅԱԼՆԵՐ</Col>
                  <Col span={12}>
                    <Form.Item
                      name='lastName'
                      label='Ազգանուն'
                      rules={[
                        {
                          required: true,
                          message: 'Ազգանունը պարտադիր է',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name='firstName'
                      label='Անուն'
                      rules={[
                        {
                          required: true,
                          message: 'Անունը պարտադիր է',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='middleName' label='Հայրանուն'><Input /></Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='sexId' label='Սեռ' className='ccp-radio'>
                      {isSuccessAvailableSexes ?
                        <Radio.Group>
                          {availableSexes.map((sex) => <Radio value={sex.id} key={sex.id}>{sex.name}</Radio>)}
                        </Radio.Group>
                        : <LoadingOutlined />
                      }
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={24}>ՏԱՐԱԾՔԱՅԻՆ</Col>
                  <Col span={12}>
                    <Form.Item name='regionId' label='Մարզ'>
                      {isSuccessAvailableRegions ?
                        <Select showSearch allowClear placeholder='Ընտրել' fieldNames={{ label: 'name', value: 'id' }} options={availableRegions} optionFilterProp='name' />
                        : <LoadingOutlined />
                      }
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='territorialCenterId' label='Տարածքային կազմակերպութ․․․'>
                      {isSuccessAvailableTerritorialCenters ?
                        <Select allowClear showSearch placeholder='Ընտրել' fieldNames={{ label: 'name', value: 'id' }} options={availableTerritorialCenters} optionFilterProp='name' />
                        : <LoadingOutlined />
                      }
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={24}>ԱՄՍԱԹՎԵՐ</Col>
                  <Col span={24}>
                    <Form.Item name='birthDate' label='ծննդյան օր'>
                      <DatePicker
                        format={{
                          format: 'DD/MM/YYYY',
                          type: 'mask'
                        }}
                        style={{width: '100%'}}
                        placeholder='Օր-Ամիս-Տարի'
                        disabledDate={(currentDate) => {
                          const now = new Date();
                          return currentDate > now;
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='dateOfApplicationCompletion' label='Դիմումի լրացման օր'>
                      <DatePicker
                        format={{
                          format: 'DD/MM/YYYY',
                          type: 'mask'
                        }}
                        style={{width: '100%'}}
                        placeholder='Օր-Ամիս-Տարի'
                        disabledDate={(currentDate) => {
                          const now = new Date();
                          return currentDate > now;
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='dateOfBecomingPartyMember' label='Կուսակցական դառնալու օր'>
                      <DatePicker
                        format={{
                          format: 'DD/MM/YYYY',
                          type: 'mask'
                        }}
                        style={{width: '100%'}}
                        placeholder='Օր-Ամիս-Տարի'
                        disabledDate={(currentDate) => {
                          const now = new Date();
                          return currentDate > now;
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={24}>ԱՆՁՆԱԳՐԱՅԻՆ ԵՎ ՄԱՍՆԱԳԻՏԱԿԱՆ ՏՎՅԱԼՆԵՐ</Col>
                  <Col span={12}>
                    <Form.Item name='professionEmployment' label='Մասնագիտական տվյալներ'>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item name='passport' label='Անձնագրային տվյալներ'><Input /></Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name='positionInThePartyId' label='կուսակցական պաշտոն'>
                      { isSuccessAvailablePositionsInTheParty ?
                        <Select allowClear placeholder='Ընտրել' fieldNames={{ label: 'name', value: 'id' }} options={availablePositionsInTheParty} optionFilterProp='name' showSearch />
                        : <LoadingOutlined />
                      }
                    </Form.Item>
                  </Col>
                </Row>

              </Col>

              <Col span={12} className='member-info-col member-edit-items' style={{ paddingLeft: '2rem' }}>

                <Row gutter={20}>
                  <Col span={24}>ԿՈՆՏԱԿՏԱՅԻՆ ՏՎՅԱԼՆԵՐ</Col>
                  <Col span={24}><Form.Item name='phoneNumber' label='հեռախոս'><Input /></Form.Item></Col>
                  <Col span={24}>
                    <Form.Item
                      name='emailAddress'
                      label='էլ. հասցե'
                      rules={[
                        {
                          type: 'email',
                          message: 'Մուտքագրեք ճիշտ էլ․ հասցե',
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  {/* <Col span={24}><Form.Item name='remark' label='նկատողություններ'><InputNumber style={{ width: '100%' }} /></Form.Item></Col> */}
                  <Col span={24}>
                    <Form.Item name='remark' label='նկատողություններ' className='ccp-radio'>
                      <Radio.Group>
                        <Radio value={'1'} key={'1'}>Այո</Radio>
                        <Radio value={'0'} key={'0'}>Ոչ</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={24}>ԿՑՎԱԾ ՖԱՅԼԵՐ</Col>
                  <Col span={24}>
                    <Form.Item
                      name='attachedFiles'
                      valuePropName='fileList'
                      getValueFromEvent={(e) => (Array.isArray(e) ? e : e?.fileList)}
                    >
                      <Upload
                        action={`${process.env.REACT_APP_API_BASE_URL}/file`}
                        itemRender={(originNode, file, fileList, { download, preview, remove }) => (
                          <div
                            className={'file-item'}
                            key={file.id}
                          >
                            <div className='file-item__type'>
                              <DocumentIcon fileName={file.name?.toLowerCase()} />
                            </div>
                            <div className='file-item__body'>
                              <div className='file-item__name'>{file.name}</div>
                              <div className='file-item__size'>{formatBytes(file.size)}</div>
                            </div>

                            <div className='file-item__action'>
                              <Button onClick={remove} download={file.name}>
                                <DeleteOutlined />
                              </Button>
                            </div>
                          </div>
                        )}
                        onChange={({ file, fileList, event }) => {
                          if (file.status !== 'uploading') {
                            // console.log(file, fileList);
                          }
                          if (file.status === 'done') {
                            // message.success(`${file.name} file uploaded successfully`);
                          } else if (file.status === 'error') {
                            message.error(`${file.name} file upload failed.`);
                          }
                        }}
                        withCredentials
                      >
                        <Button className='upload-files' icon={<UploadOutlined />}>Վերբեռնել ֆայլ</Button>
                      </Upload>
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item name='notes' label='նշումներ'>
                      <Input.TextArea rows={6} placeholder='Գրել տեքստ․․․' />
                    </Form.Item>
                  </Col>
                </Row>

                <Row gutter={20}>
                  <Col span={24}>
                    <Form.Item name='memberStatusId' label='Կարգավիճակ' className='ccp-radio'>
                      {isSuccessAvailableMemberStatuses ?
                        <Radio.Group>
                          {availableMemberStatuses.map((status) => <Radio value={status.id} key={status.id}><MemberStatusTag status={status} /></Radio>)}
                        </Radio.Group>
                        : <LoadingOutlined />
                      }
                    </Form.Item>
                  </Col>
                </Row>

              </Col>
            </Row>
          </Col>
        </Row>
        <Flex justify='flex-end'><Button type='primary' htmlType='submit' icon={<EditOutlined />}>Պահպանել</Button></Flex>
      </Form>
    )
  }

  return <div><Skeleton /></div>
}