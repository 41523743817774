import React from 'react';
import Icon, { FileUnknownOutlined } from '@ant-design/icons';
import { ReactComponent as PDFSvg } from '../../../assets/icons/file-types/PDF.svg';
import { ReactComponent as CSVSvg } from '../../../assets/icons/file-types/CSV.svg';
import { ReactComponent as DOCSvg } from '../../../assets/icons/file-types/DOC.svg';
import { ReactComponent as DOCXSvg } from '../../../assets/icons/file-types/DOCX.svg';
import { ReactComponent as XSLSvg } from '../../../assets/icons/file-types/XSL.svg';
import { ReactComponent as PNGSvg } from '../../../assets/icons/file-types/PNG.svg';
import { ReactComponent as JPGSvg } from '../../../assets/icons/file-types/JPG.svg';
import { ReactComponent as MP4Svg } from '../../../assets/icons/file-types/MP4.svg';
import { ReactComponent as UNKNOWNSvg } from '../../../assets/icons/file-types/UNKNOWN.svg';

const allowedFileTypes: any = {
  pdf: PDFSvg,
  csv: CSVSvg,
  doc: DOCSvg,
  docx: DOCXSvg,
  xls: XSLSvg,
  png: PNGSvg,
  jpg: JPGSvg,
  jpeg: JPGSvg,
  mp4: MP4Svg,
}

export default function DocumentIcon({fileName}: {fileName: string}) {

  const re = /(?:\.([^.]+))?$/;
  const ext = re.exec(fileName);

  if (ext && ext[1] in allowedFileTypes) {
    return <Icon component={allowedFileTypes[ext[1]]} style={{ fontSize: '52px' }} />
  }

  return <Icon component={UNKNOWNSvg} style={{ fontSize: '52px' }} />
}