import React, {useContext} from "react";
import { Routes, Navigate, Route, useLocation } from "react-router-dom";
import RootLayout from "./layouts/RootLayout";
import LoginScreen from "./features/auth/LoginScreen";
import MembersScreen from "./features/members/MembersScreen";
import MemberLayout from "./features/members/MemberLayout";
import MemberScreen from "./features/members/MemberScreen";
import AddOrEditMember from "./features/members/AddOrEditMember";
import DocumentsFoldersStructureScreen from "./features/documents/DocumentsFoldersStructureScreen";
import DocumentsScreen from "./features/documents/DocumentsScreen";
import AdminsScreen from "./features/admins/AdminsScreen";
import { Result, Button } from "antd";

import { AuthContext } from "./features/auth/AuthProvider";

export default function AppRoutes() {

  const { isLoggedIn } = useContext(AuthContext);

  return(
    <Routes>
      <Route element={<RootLayout />}>
        <Route path='/' element={<Navigate to={isLoggedIn ? '/members' : '/login'} />} />
        <Route path='/login' element={<LoginScreen />} />
        <Route path='/members' element={<RequireAuth allowedRoles={['Admin', 'SuperAdmin', 'Member']}><MembersScreen /></RequireAuth>} />
        <Route path='/member' element={<MemberLayout />}>
          <Route index element={<Navigate to='/members' />} />
          <Route path='add' element={<RequireAuth allowedRoles={['Admin', 'SuperAdmin']}><AddOrEditMember /></RequireAuth>} id='asas' />
          <Route path=':memberId' element={<RequireAuth allowedRoles={['Admin', 'SuperAdmin', 'Member']}><MemberScreen /></RequireAuth>} />
          <Route path=':memberId/edit' element={<RequireAuth allowedRoles={['Admin', 'SuperAdmin']}><AddOrEditMember /></RequireAuth>} />
        </Route>
        <Route path='/documents' element={<RequireAuth allowedRoles={['Admin', 'SuperAdmin', 'Member']}><DocumentsFoldersStructureScreen /></RequireAuth>} />
        <Route path='/documents/:belongsTo/:folderId' element={<RequireAuth allowedRoles={['Admin', 'SuperAdmin', 'Member']}><DocumentsScreen /></RequireAuth>} />
        <Route path='/admins' element={<RequireAuth allowedRoles={['SuperAdmin', 'Admin']}><AdminsScreen /></RequireAuth>} />
        <Route path='*' element={<div>Not Found</div>} />
      </Route>
    </Routes>
  )
}

function RequireAuth({ children, allowedRoles = [] }: { children: JSX.Element, allowedRoles?: string[] }) {
  const { isLoggedIn, role } = useContext(AuthContext);
  let location = useLocation();

  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  if (!allowedRoles.includes(role)) {
    return (
      <Result
        status="403"
        title="403"
        subTitle="Ներեցեք, դուք իրավասու չեք մուտք գործել այս էջ:"
        extra={<Button type="primary">Ետ գնալ</Button>}
      />
    )
  }

  return children;
}
